import { Play } from '../context/PlaysCollectionProvider';

export const getPlayerIdsWithScores = (play: Play, golfRules: boolean) => {
  return play.playerIds
    .map((id): [string, number] => [id, play.getPlayerTotalScore(id)])
    .sort(([_a, a], [_b, b]) => (golfRules ? a - b : b - a));
};

export const getPlayerIdsByScore = (play: Play, golfRules: boolean) => {
  return getPlayerIdsWithScores(play, golfRules).map(([id]) => id);
};

export const getWinnerIds = (play: Play, golfRules: boolean) => {
  const playerIdsWithScores = getPlayerIdsWithScores(play, golfRules);

  const [, winnerScore] = playerIdsWithScores[0];
  const winners: string[] = [];

  for (let i = 0; i < playerIdsWithScores.length; i++) {
    const [id, score] = playerIdsWithScores[i];
    if (score === winnerScore) {
      winners.push(id);
    } else {
      break;
    }
  }

  return winners;
};
