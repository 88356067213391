import { useHistory } from 'react-router-dom';

interface NavigateOptions {
  replace?: boolean;
}

const useNavigate = () => {
  const history = useHistory();
  const path = history.location.pathname;

  const navigate = (path: string, options?: NavigateOptions) => {
    if (options?.replace) {
      history.replace(path);
    } else {
      history.push(path);
    }
  };

  return {
    path: path,
    push: history.push,
    replace: history.replace,
    up: (count = 1, options?: NavigateOptions) => {
      let newPath = path;
      for (let i = 0; i < count; i++) {
        newPath = newPath.substring(0, newPath.lastIndexOf('/'));
      }
      navigate(newPath, options);
    },
    to: (newPath: string, options?: NavigateOptions) =>
      navigate(`${path}/${newPath}`, options),
  };
};

export default useNavigate;
