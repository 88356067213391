import { useState, useEffect } from 'react';
import firebase from 'firebase';
import { useAuth } from '../context/AuthProvider';

interface Timestamp {
  seconds: number;
  nanoseconds: number;
}

export interface DocumentData {
  lastModified?: Timestamp;
}

export class Document<D extends DocumentData> {
  constructor(public id: string, protected data: D) {}

  get doc() {
    return this.data;
  }

  get lastModified() {
    if (this.data.lastModified) {
      const { seconds, nanoseconds } = this.data.lastModified;
      return new firebase.firestore.Timestamp(seconds, nanoseconds);
    } else {
      return firebase.firestore.Timestamp.now();
    }
  }

  /**
   * Returns the compare value between this and another document
   * @param other a document of the same type to compare to
   */
  compareTo(other: Document<D>) {
    // Compares IDs for stable sorting between documents
    if (this.id < other.id) return -1;
    return 1;
  }
}

interface CollectionMethods<D, T> {
  add: (documentData: D) => Promise<string>;
  set: (document: T) => void;
  delete: (id: string) => void;
  get: (id?: string) => T | undefined;
  getAll: (ids?: string[]) => T[];
}

type CollectionData<T> =
  | LoadingCollection
  | SuccessCollection<T>
  | ErrorCollection;

interface LoadingCollection {
  status: 'loading';
  data: undefined;
  error: undefined;
}

interface SuccessCollection<T> {
  status: 'success';
  data: T[];
  error: undefined;
}

interface ErrorCollection {
  status: 'error';
  data: undefined;
  error: Error;
}

export type Collection<
  D extends DocumentData,
  T extends Document<D>
> = CollectionMethods<D, T> & CollectionData<T>;

type Constructor<T> = new (id: string, data: any) => T;

const useCollection = <D extends DocumentData, T extends Document<D>>(
  collectionPath: string,
  ctor: Constructor<T>
): Collection<D, T> => {
  const [user] = useAuth();

  const [state, setState] = useState<CollectionData<T>>({
    status: 'loading',
    data: undefined,
    error: undefined,
  });

  useEffect(() => {
    return firebase
      .firestore()
      .collection(collectionPath)
      .where('ownerId', '==', user.uid)
      .onSnapshot(
        (snapshot) => {
          const docs = snapshot.docs.map((doc) => new ctor(doc.id, doc.data()));
          docs.sort((a, b) => a.compareTo(b));

          if (state.status === 'loading') {
            setTimeout(() => {
              setState({
                status: 'success',
                data: docs,
                error: undefined,
              });
            }, 1000);
          } else {
            setState({
              status: 'success',
              data: docs,
              error: undefined,
            });
          }
        },
        (error) => {
          setState({
            status: 'error',
            data: undefined,
            error: error,
          });
        }
      );
  }, [user.uid, collectionPath, ctor, state.status]);

  const assertCollection = () => {
    if (state.status !== 'success') {
      throw new Error(
        'Attempted operation on collection when it is not available'
      );
    }
  };

  const methods = {
    async add(documentData: D) {
      assertCollection();
      const ref = await firebase
        .firestore()
        .collection(collectionPath)
        .add({
          ...documentData,
          lastModified: firebase.firestore.FieldValue.serverTimestamp(),
        });
      return ref.id;
    },
    set(document: T) {
      assertCollection();
      firebase
        .firestore()
        .collection(collectionPath)
        .doc(document.id)
        .set({
          ...document.doc,
          lastModified: firebase.firestore.FieldValue.serverTimestamp(),
        });
    },
    delete(id: string) {
      assertCollection();
      firebase.firestore().collection(collectionPath).doc(id).delete();
    },
    get(id?: string) {
      if (!id || state.status !== 'success') {
        return undefined;
      }
      return state.data!.find((doc) => doc.id === id);
    },
    getAll(ids: string[] = []) {
      if (state.status !== 'success') {
        return [];
      }
      const documents: T[] = [];
      ids.forEach((id) => {
        const doc = state.data.find((doc) => doc.id === id);
        if (doc) {
          documents.push(doc);
        }
      });
      return documents;
    },
  };

  return { ...state, ...methods };
};

export default useCollection;
