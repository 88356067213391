import React, { FunctionComponent, createContext, useContext } from 'react';
import useCollection, {
  Document,
  Collection,
  DocumentData,
} from '../hooks/useCollection';

export interface GameRules {
  roundTimer: number | null;
  playToScore: number | null;
  playForRounds: number | null;
  winBy: number | null;
}

export interface GameData extends GameRules, DocumentData {
  name: string;
  avatarColor: string;
  ownerId: string;
  playerCount: NumberRange;
  golfRules: boolean;
  roundBased: boolean;
}

export class Game extends Document<GameData> {
  public static isValid(data: GameData) {
    return (
      !!data.name.trim() &&
      !!data.avatarColor &&
      !!data.ownerId &&
      data.playerCount.min <= data.playerCount.max
    );
  }

  public get name() {
    return this.data.name;
  }

  public get avatarColor() {
    return this.data.avatarColor;
  }

  public get ownerId() {
    return this.data.ownerId;
  }

  public get avatarText() {
    return this.data.name[0].toUpperCase();
  }

  public get playerCount() {
    return this.data.playerCount;
  }

  public get golfRules() {
    return this.data.golfRules;
  }

  public get roundBased() {
    return this.data.roundBased;
  }

  public get roundTimer() {
    return this.data.roundTimer;
  }

  public get playToScore() {
    return this.data.playToScore;
  }

  public get playForRounds() {
    return this.data.playForRounds;
  }

  public get winBy() {
    return this.data.winBy;
  }

  public get rules(): GameRules {
    return {
      roundTimer: this.roundTimer,
      playToScore: this.playToScore,
      playForRounds: this.playForRounds,
      winBy: this.winBy,
    };
  }

  /**
   * @override
   */
  compareTo(other: Game) {
    let a = this.name.toLowerCase();
    let b = other.name.toLowerCase();

    if (a < b) return -1;
    if (a > b) return 1;

    // use default sorting as tie-breaker
    return super.compareTo(other);
  }
}

const GamesCollectionContext = createContext<Collection<GameData, Game> | null>(
  null
);

const GamesCollectionProvider: FunctionComponent = ({ children }) => {
  const games = useCollection('games', Game);

  return (
    <GamesCollectionContext.Provider value={games}>
      {children}
    </GamesCollectionContext.Provider>
  );
};

export const useGamesCollection = () => {
  const games = useContext(GamesCollectionContext);

  if (!games) {
    throw new Error(
      'Must use useGamesCollection within GamesCollectionProvider'
    );
  }

  return games;
};

export default GamesCollectionProvider;
