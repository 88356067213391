import React, { PropsWithChildren } from 'react';
import { Fab, Box, Zoom, Container, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import DocumentList from './DocumentList';
import Header from './Header';
import { Alert } from '@material-ui/lab';
import UserMenu from './UserMenu';
import { Document, Collection, DocumentData } from '../hooks/useCollection';
import { useTranslation } from 'react-i18next';
import useNavigate from '../hooks/useNavigate';
import { Link } from 'react-router-dom';

const BottomRightFab: typeof Fab = styled(Fab)`
  position: fixed;
  right: ${({ theme }) => theme.spacing(2)}px;
  bottom: ${({ theme }) => theme.spacing(9)}px;
`;

interface DocumentListPageProps<D extends DocumentData, T extends Document<D>> {
  collection: Collection<D, T>;
  title: string;
  render: (document: T) => JSX.Element;
  categories?: Category<T>[];
  emptyListMessage?: string;
}

const DocumentListPage = <D extends DocumentData, T extends Document<D>>({
  collection,
  title,
  render,
  categories,
  emptyListMessage,
}: PropsWithChildren<DocumentListPageProps<D, T>>) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <>
      <Header title={title} rightAction={<UserMenu />} />

      {collection.status === 'error' ? (
        <Box p={2}>
          <Alert severity="error">{collection.error.message}</Alert>
        </Box>
      ) : (
        <Container>
          <DocumentList
            documents={collection.data}
            onDocumentClicked={(document) => navigate.to(document.id)}
            render={render}
            categories={categories}
          />

          {collection.data && collection.data.length === 0 && (
            <Box
              position="fixed"
              top={0}
              left={0}
              width="100vw"
              height="100vh"
              padding={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography align="center">{emptyListMessage}</Typography>
            </Box>
          )}

          {collection.status === 'success' ? (
            <Zoom in>
              <BottomRightFab
                component={Link}
                to={`${navigate.path}/new`}
                aria-label={t('actions.add')}
                color="secondary"
              >
                <AddIcon />
              </BottomRightFab>
            </Zoom>
          ) : null}
        </Container>
      )}
    </>
  );
};

export default DocumentListPage;
