import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import GamesListPage from './GamesListPage';
import GamePage from './GamePage';
import CreateGamePage from './CreateGamePage';
import EditGamePage from './EditGamePage';

const GamesRouter: FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/games" exact component={GamesListPage} />
      <Route path="/games/new" component={CreateGamePage} />
      <Route path="/games/:id" exact component={GamePage} />
      <Route path="/games/:id/edit" component={EditGamePage} />
    </Switch>
  );
};

export default GamesRouter;
