import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import PlaysListPage from './PlaysListPage';
import CreatePlayPage from './CreatePlayPage';
import PlayPage from './PlayPage';
import EndPlayPage from './EndPlayPage';
import PlayResultsPage from './PlayResultsPage';

const PlaysRouter: FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/plays" exact component={PlaysListPage} />
      <Route path="/plays/new" component={CreatePlayPage} />
      <Route path="/plays/:id/end-play" exact component={EndPlayPage} />
      <Route path="/plays/:id/results" exact component={PlayResultsPage} />
      <Route path="/plays/:id" component={PlayPage} />
    </Switch>
  );
};

export default PlaysRouter;
