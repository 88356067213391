import { makeStyles } from '@material-ui/styles';
import * as colors from '@material-ui/core/colors';
import { Theme } from '@material-ui/core';

export const useColorClasses: (
  props?: any
) => Record<string, string> = makeStyles((theme: Theme) =>
  Object.entries(colors)
    .filter(([key]) => key !== 'common')
    .reduce(
      (acc, [key, palette]) => {
        return {
          ...acc,
          [key]: {
            backgroundColor: (palette as any)[500],
            color: theme.palette.getContrastText((palette as any)[500]),
          },
        };
      },
      {
        themePrimaryColor: {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
        },
      }
    )
);
