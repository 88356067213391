import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlaysCollection } from '../../context/PlaysCollectionProvider';
import DocumentListPage from '../DocumentListPage';
import { useGamesCollection } from '../../context/GamesCollectionProvider';
import PlayListItem from './PlayListItem';

const PlaysListPage: FunctionComponent = () => {
  const plays = usePlaysCollection();
  const games = useGamesCollection();
  const { t } = useTranslation();

  return (
    <DocumentListPage
      title={t('plays_title')}
      collection={plays}
      categories={[
        {
          title: t('play.in_progress_subheader'),
          predicate: (play) =>
            ['in-progress', 'continued'].includes(play.status),
        },
        {
          title: t('play.finished_subheader'),
          predicate: (play) => play.status === 'finished',
        },
      ]}
      emptyListMessage={t('play.empty_list')}
      render={(play) => {
        const game = games.get(play.gameId);

        return <PlayListItem play={play} game={game!} />;
      }}
    />
  );
};

export default PlaysListPage;
