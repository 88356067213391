import React, { FunctionComponent } from 'react';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import { usePlayersCollection } from '../../context/PlayersCollectionProvider';
import { useTranslation } from 'react-i18next';
import DocumentListPage from '../DocumentListPage';
import ColorAvatar from '../ColorAvatar';

const PlayersListPage: FunctionComponent = () => {
  const players = usePlayersCollection();
  const { t } = useTranslation();

  return (
    <DocumentListPage
      title={t('players_title')}
      collection={players}
      emptyListMessage={t('player.empty_list')}
      render={(player) => (
        <>
          <ListItemAvatar>
            <ColorAvatar color={player.avatarColor}>
              {player.avatarText}
            </ColorAvatar>
          </ListItemAvatar>
          <ListItemText>{player.name}</ListItemText>
        </>
      )}
    />
  );
};

export default PlayersListPage;
