import React, { FunctionComponent, Suspense } from 'react';
import firebase from 'firebase';
import AuthProvider from './AuthProvider';
import StyleProvider from './StyleProvider';
import { CircularProgress } from '@material-ui/core';

const config = {
  apiKey: 'AIzaSyBXAbsWcrwuGHFbiP_phzY9LiWiOBLYOjQ',
  authDomain: 'game-keep.firebaseapp.com',
  databaseURL: 'https://game-keep.firebaseio.com',
  projectId: 'game-keep',
  storageBucket: 'game-keep.appspot.com',
  messagingSenderId: '289357057877',
  appId: '1:289357057877:web:db457cab095913359a29dc',
  measurementId: 'G-FDQZV85SJ5',
};
firebase.initializeApp(config);
firebase
  .firestore()
  .enablePersistence()
  .catch((error) => {
    console.error(error);
  });
firebase.analytics();

const AppProviders: FunctionComponent = ({ children }) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <StyleProvider>
        <AuthProvider>{children}</AuthProvider>
      </StyleProvider>
    </Suspense>
  );
};

export default AppProviders;
