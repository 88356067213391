import React, { FunctionComponent } from 'react';
import PlaysCollectionProvider from './PlaysCollectionProvider';
import GamesCollectionProvider from './GamesCollectionProvider';
import PlayersCollectionProvider from './PlayersCollectionProvider';

const AuthenticatedAppProviders: FunctionComponent = ({ children }) => {
  return (
    <PlaysCollectionProvider>
      <GamesCollectionProvider>
        <PlayersCollectionProvider>{children}</PlayersCollectionProvider>
      </GamesCollectionProvider>
    </PlaysCollectionProvider>
  );
};

export default AuthenticatedAppProviders;
