import React, { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import {
  Typography,
  CircularProgress,
  IconButton,
  Container,
  Fab,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import DocumentActions, { DeleteDocumentAction } from './DocumentActions';
import { Collection, Document, DocumentData } from '../hooks/useCollection';
import useNavigate from '../hooks/useNavigate';
import styled from 'styled-components';

const BottomRightFab: typeof Fab = styled(Fab)`
  position: fixed;
  right: ${({ theme }) => theme.spacing(2)}px;
  bottom: ${({ theme }) => theme.spacing(2)}px;
`;

interface DocumentPageProps<D extends DocumentData, T extends Document<D>> {
  collection: Collection<D, T>;
  render: (document: T) => JSX.Element;
}

const DocumentPage = <D extends DocumentData, T extends Document<D>>({
  collection,
  render,
}: PropsWithChildren<DocumentPageProps<D, T>>) => {
  const { id } = useParams();
  const document = collection.get(id);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onDeleteClicked = () => {
    collection.delete(id);
    navigate.up();
  };

  return (
    <>
      <Header
        leftAction={
          <IconButton
            onClick={() => navigate.up()}
            size="small"
            aria-label={t('actions.back')}
          >
            <ArrowBackIcon />
          </IconButton>
        }
        rightAction={
          <DocumentActions disabled={collection.status !== 'success'}>
            <DeleteDocumentAction onClick={onDeleteClicked} />
          </DocumentActions>
        }
      />

      <Container>
        {collection.status === 'loading' ? (
          <CircularProgress />
        ) : collection.status === 'error' ? (
          <div>{collection.error.message}</div>
        ) : document ? (
          render(document)
        ) : (
          <Typography>This game is unavailable</Typography>
        )}
      </Container>

      <BottomRightFab
        color="secondary"
        onClick={() => navigate.to('edit')}
        aria-label={t('actions.edit')}
      >
        <EditIcon />
      </BottomRightFab>
    </>
  );
};

export default DocumentPage;
