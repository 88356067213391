import React, { FunctionComponent } from 'react';
import { usePlayersCollection } from '../../context/PlayersCollectionProvider';
import { Typography, Box } from '@material-ui/core';
import DocumentPage from '../DocumentPage';
import ColorAvatar from '../ColorAvatar';
import styled from 'styled-components';

const BigAvatarBox = styled(Box)`
  .MuiAvatar-root {
    width: ${({ theme }) => theme.spacing(12)}px;
    height: ${({ theme }) => theme.spacing(12)}px;
  }
`;

const PlayerPage: FunctionComponent = () => {
  const players = usePlayersCollection();

  return (
    <DocumentPage
      collection={players}
      render={(player) => (
        <Box display="flex" flexDirection="column" alignItems="center">
          <BigAvatarBox p={2}>
            <ColorAvatar color={player.avatarColor}>
              <Typography variant="h3">{player.avatarText}</Typography>
            </ColorAvatar>
          </BigAvatarBox>

          <Typography component="h1" variant="h6">
            {player.name}
          </Typography>
        </Box>
      )}
    />
  );
};

export default PlayerPage;
