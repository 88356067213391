import React, {
  createContext,
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from 'react';
import firebase from 'firebase';
import { CircularProgress } from '@material-ui/core';

const AuthContext = createContext<[firebase.User | null, () => Promise<void>]>([
  null,
  async () => {},
]);

const AuthProvider: FunctionComponent = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null | undefined>(undefined);

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  if (user === undefined) {
    return <CircularProgress />;
  }

  const signOut = () => firebase.auth().signOut();

  return (
    <AuthContext.Provider value={[user, signOut]}>
      {children}
    </AuthContext.Provider>
  );
};

export const useUser = () => {
  const [user] = useContext(AuthContext);
  return user;
};

export const useAuth = (): [firebase.User, () => Promise<void>] => {
  const [user, signOut] = useContext(AuthContext);
  if (!user) {
    throw new Error('useAuth must be used within an authenticated app');
  }
  return [user, signOut];
};

export default AuthProvider;
