import React, { FunctionComponent } from 'react';
import { useAuth } from '../context/AuthProvider';
import { Avatar, IconButton, Menu, MenuItem } from '@material-ui/core';
import styled from 'styled-components';
import useMenu from '../hooks/useMenu';

const UserAvatar = styled(Avatar)`
  width: ${({ theme }) => theme.spacing(4)}px;
  height: ${({ theme }) => theme.spacing(4)}px;
`;

const UserMenu: FunctionComponent = () => {
  const [user, signOut] = useAuth();
  const [anchorEl, open, toggleOpen] = useMenu();

  return (
    <>
      <IconButton size="small" onClick={toggleOpen}>
        <UserAvatar
          alt={user.displayName || undefined}
          src={user.photoURL || undefined}
        />
      </IconButton>

      <Menu open={open} anchorEl={anchorEl} onClose={toggleOpen} keepMounted>
        <MenuItem onClick={signOut}>Sign out</MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
