import React, { FunctionComponent } from 'react';
import { ListItem, ListItemIcon } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';
import SettingsList from '../styles/SettingsList';

const ColorPickerSkeleton = styled(Skeleton)`
  margin: 3px auto;
`;

interface SettingsListSkeleton {
  colorPicker?: boolean;
  settingsCount: number;
}

const SettingsListSkeleton: FunctionComponent<SettingsListSkeleton> = ({
  colorPicker = false,
  settingsCount,
}) => {
  return (
    <SettingsList>
      {colorPicker && (
        <ListItem>
          <ColorPickerSkeleton variant="circle" width={56} height={56} />
        </ListItem>
      )}

      {new Array(settingsCount).fill('').map((_, i) => (
        <ListItem key={i}>
          <ListItemIcon>
            <Skeleton variant="circle" width={24} height={24} />
          </ListItemIcon>
          <Skeleton variant="rect" height={56} width="100%" />
        </ListItem>
      ))}
    </SettingsList>
  );
};

export default SettingsListSkeleton;
