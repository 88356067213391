import React, { FunctionComponent } from 'react';
import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, StylesProvider } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import { cyan, yellow } from '@material-ui/core/colors';
import ThemeProvider from './ThemeProvider';
import { createGlobalStyle } from 'styled-components';

const defaultTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: cyan[300],
    },
    secondary: yellow,
    error: red,
    background: {
      default: '#242424',
      paper: '#242424e3',
    },
  },
  overrides: {
    MuiToolbar: {
      regular: {
        '@media (orientation: landscape)': {
          minHeight: `${defaultTheme.spacing(7)}px`,
        },
      },
    },
  },
  props: {
    MuiContainer: {
      maxWidth: 'sm',
    },
    MuiDialog: {
      maxWidth: 'xs',
      fullWidth: true,
    },
  },
});

const GlobalStyles = createGlobalStyle`
  .MuiStepper-root {
    background-color: ${({ theme }) => theme.palette.background.default};
  }

  .MuiContainer-root {
    padding: 0;
  }

  .MuiDialogTitle-root {
    padding-bottom: 0;
  }

  .MuiPaper-root, .MuiBottomNavigation-root {
    backdrop-filter: blur(5px);
  }
`;

const StyleProvider: FunctionComponent = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StylesProvider injectFirst>
        <GlobalStyles />
        {children}
      </StylesProvider>
    </ThemeProvider>
  );
};

export default StyleProvider;
