import React, { FunctionComponent } from 'react';
import { Typography, AppBar, Toolbar } from '@material-ui/core';
import styled from 'styled-components';
import Inline from './layout/Inline';

const AppBarContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(7)}px;

  .asdf {
    flex-grow: 1;
  }

  .MuiAppBar-root {
    background-color: ${({ theme }) => theme.palette.background.paper};
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

interface HeaderProps {
  title?: string;
  leftAction?: JSX.Element;
  rightAction?: JSX.Element;
}

const Header: FunctionComponent<HeaderProps> = ({
  title,
  leftAction,
  rightAction,
}) => {
  return (
    <AppBarContainer>
      <AppBar elevation={1}>
        <Toolbar>
          <Inline gap={2} className="asdf">
            {leftAction}

            <Typography component="h1" variant="h6">
              {title}
            </Typography>
          </Inline>

          <Inline reverse gap={1}>
            {rightAction}
          </Inline>
        </Toolbar>
      </AppBar>
    </AppBarContainer>
  );
};

export default Header;
