import React, { FunctionComponent } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from '@material-ui/core';
import { SettingComponentProps } from './Setting';

type BooleanSettingProps = Omit<SettingComponentProps<boolean>, 'optional'> & {
  onDescription: string;
  offDescription: string;
};

const BooleanSetting: FunctionComponent<BooleanSettingProps> = ({
  id,
  icon,
  label,
  value,
  onChange,
  onDescription,
  offDescription,
}) => {
  const labelId = `${id}-label`;

  return (
    <ListItem button onClick={() => onChange(!value)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        id={labelId}
        primary={label}
        secondary={value ? onDescription : offDescription}
      />
      <ListItemSecondaryAction>
        <Switch
          id={id}
          color="primary"
          edge="end"
          checked={value ?? false}
          onChange={() => onChange(!value)}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default BooleanSetting;
