import React, { FunctionComponent } from 'react';
import useNavigate from '../../hooks/useNavigate';
import CreatePlayerSheet from './CreatePlayerSheet';

const CreatePlayerPage: FunctionComponent = () => {
  const navigate = useNavigate();

  return <CreatePlayerSheet onClose={() => navigate.up()} />;
};

export default CreatePlayerPage;
