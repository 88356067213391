import React, { FunctionComponent } from 'react';
import { Avatar } from '@material-ui/core';
import { useColorClasses } from '../hooks/useColorClasses';

interface ColorAvatarProps {
  color: string;
}

const ColorAvatar: FunctionComponent<ColorAvatarProps> = ({
  children,
  color,
}) => {
  const colorClasses = useColorClasses();

  return <Avatar className={colorClasses[color]}>{children}</Avatar>;
};

export default ColorAvatar;
