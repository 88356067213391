import {
  usePlaysCollection,
  PlayData,
  Play,
} from '../context/PlaysCollectionProvider';
import {
  useGamesCollection,
  GameData,
  Game,
} from '../context/GamesCollectionProvider';
import {
  usePlayersCollection,
  PlayerData,
  Player,
} from '../context/PlayersCollectionProvider';
import { useParams } from 'react-router-dom';
import { Collection } from './useCollection';

interface LoadingStatus {
  status: 'loading';
  error: undefined;
  playsCollection: Collection<PlayData, Play>;
  gamesCollection: Collection<GameData, Game>;
  playersCollection: Collection<PlayerData, Player>;
  play: undefined;
  game: undefined;
  players: undefined;
}

interface ErrorStatus {
  status: 'error';
  error?: Error;
  playsCollection: Collection<PlayData, Play>;
  gamesCollection: Collection<GameData, Game>;
  playersCollection: Collection<PlayerData, Player>;
  play: undefined;
  game: undefined;
  players: undefined;
}

interface SuccessStatus {
  status: 'success';
  error: undefined;
  playsCollection: Collection<PlayData, Play>;
  gamesCollection: Collection<GameData, Game>;
  playersCollection: Collection<PlayerData, Player>;
  play: Play;
  game: Game;
  players: Player[];
}

const useCurrentPlay = (): LoadingStatus | ErrorStatus | SuccessStatus => {
  const { id } = useParams();

  const playsCollection = usePlaysCollection();
  const gamesCollection = useGamesCollection();
  const playersCollection = usePlayersCollection();

  const collections = [playsCollection, gamesCollection, playersCollection];

  const status = collections.some((c) => c.status === 'error')
    ? 'error'
    : collections.some((c) => c.status === 'loading')
    ? 'loading'
    : 'success';

  const base = {
    playsCollection,
    gamesCollection,
    playersCollection,
    error: undefined,
    play: undefined,
    game: undefined,
    players: undefined,
  };

  if (status === 'loading') {
    return {
      ...base,
      status,
    };
  } else if (status === 'error') {
    return {
      ...base,
      status,
      error: collections.find((c) => c.status === 'error')?.error,
    };
  } else {
    const play = playsCollection.get(id);
    const game = gamesCollection.get(play?.gameId);
    const players = playersCollection.getAll(play?.playerIds);
    return {
      ...base,
      status,
      play: play!,
      game: game!,
      players,
    };
  }
};

export default useCurrentPlay;
