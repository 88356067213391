import * as colors from '@material-ui/core/colors';

const excludedColors = ['common', 'grey'];
const avatarColors = Object.keys(colors).filter(
  (color) => !excludedColors.includes(color)
);

export const randomAvatarColor = () => {
  return avatarColors[Math.floor(Math.random() * (avatarColors.length - 1))];
};

export default avatarColors;
