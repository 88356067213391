import React, { FunctionComponent } from 'react';
import { IconButton, Avatar, Menu } from '@material-ui/core';
import PaletteIcon from '@material-ui/icons/Palette';
import styled from 'styled-components';
import { useColorClasses } from '../hooks/useColorClasses';
import useMenu from '../hooks/useMenu';
import avatarColors from '../utils/avatarColors';

const ColorPickerButton = styled(IconButton)`
  margin: 0 auto;
  .MuiAvatar-root {
    width: ${({ theme }) => theme.spacing(7)}px;
    height: ${({ theme }) => theme.spacing(7)}px;
  }
`;

const ColorItem = styled.span`
  width: ${({ theme }) => theme.spacing(3)}px;
  height: ${({ theme }) => theme.spacing(3)}px;
  border-radius: 100%;
  transition: transform 0.2s;

  &.selected {
    transform: scale(1.5);
  }
`;

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
  label?: string;
}

const ColorPicker: FunctionComponent<ColorPickerProps> = ({
  color,
  onChange,
  label,
}) => {
  const colorClasses = useColorClasses();
  const [anchorEl, open, toggleOpen] = useMenu();

  const onColorSelected = (color: string) => {
    toggleOpen();
    onChange(color);
  };

  return (
    <>
      <ColorPickerButton size="small" onClick={toggleOpen} aria-label={label}>
        <Avatar className={colorClasses[color]}>
          <PaletteIcon />
        </Avatar>
      </ColorPickerButton>

      <Menu open={open} anchorEl={anchorEl} keepMounted onClose={toggleOpen}>
        {avatarColors.map((key) => (
          <IconButton
            aria-label={key}
            key={key}
            onClick={() => onColorSelected(key)}
          >
            <ColorItem
              className={`${colorClasses[key]} ${
                key === color ? 'selected' : ''
              }`}
            />
          </IconButton>
        ))}
      </Menu>
    </>
  );
};

export default ColorPicker;
