import React from 'react';
import { useUser } from '../context/AuthProvider';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import AuthenticatedAppProviders from '../context/AuthenticatedAppProviders';

function App() {
  const user = useUser();
  return user ? (
    <AuthenticatedAppProviders>
      <AuthenticatedApp />
    </AuthenticatedAppProviders>
  ) : (
    <UnauthenticatedApp />
  );
}

export default App;
