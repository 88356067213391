import { IconButton } from '@material-ui/core';
import styled from 'styled-components';

const TextIconButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  padding: 0;

  .MuiTypography-root {
    white-space: nowrap;
    text-transform: none;
  }
`;

export default TextIconButton;
