import React, { FunctionComponent } from 'react';
import firebase from 'firebase';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { Container, Typography, Box, Paper } from '@material-ui/core';

const UnauthenticatedApp: FunctionComponent = () => {
  const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Paper>
          <Box p={2}>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <StyledFirebaseAuth
              uiConfig={uiConfig}
              firebaseAuth={firebase.auth()}
            />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default UnauthenticatedApp;
