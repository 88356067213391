import React, { FunctionComponent } from 'react';
import { Theme, MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider as ScThemeProvider } from 'styled-components';

interface ThemeProviderProps {
  theme: Theme;
}

const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({
  children,
  theme,
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <ScThemeProvider theme={theme}>{children}</ScThemeProvider>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
