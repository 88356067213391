import React, { FunctionComponent } from 'react';
import {
  usePlayersCollection,
  Player,
  PlayerData,
} from '../../context/PlayersCollectionProvider';
import { useAuth } from '../../context/AuthProvider';
import { randomAvatarColor } from '../../utils/avatarColors';
import EditPlayer from './EditPlayer';
import { useTranslation } from 'react-i18next';
import CreateDocumentSheet from '../CreateDocumentSheet';

interface CreatePlayerSheetProps {
  onClose: () => void;
  onSave?: (id: string) => void;
}

const CreatePlayerSheet: FunctionComponent<CreatePlayerSheetProps> = ({
  onClose,
  onSave,
}) => {
  const [user] = useAuth();
  const players = usePlayersCollection();

  const { t } = useTranslation();

  const onSaveInternal = async (playerData: PlayerData) => {
    onClose();
    const id = await players.add(playerData);
    if (onSave) {
      onSave(id);
    }
  };

  return (
    <CreateDocumentSheet
      title={t('create_player_title')}
      initialDocumentData={
        {
          avatarColor: randomAvatarColor(),
          name: 'New player',
          ownerId: user.uid,
        } as PlayerData
      }
      isValid={Player.isValid}
      onSave={onSaveInternal}
      onCancel={onClose}
      render={(playerData, setPlayerData) => (
        <EditPlayer playerData={playerData} onChange={setPlayerData} />
      )}
    />
  );
};

export default CreatePlayerSheet;
