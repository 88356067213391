import React, { FunctionComponent } from 'react';
import { Typography, Box } from '@material-ui/core';
import { useGamesCollection } from '../../context/GamesCollectionProvider';
import DocumentPage from '../DocumentPage';
import ColorAvatar from '../ColorAvatar';
import styled from 'styled-components';

const BigAvatarBox = styled(Box)`
  .MuiAvatar-root {
    width: ${({ theme }) => theme.spacing(12)}px;
    height: ${({ theme }) => theme.spacing(12)}px;
  }
`;

const GamePage: FunctionComponent = () => {
  const games = useGamesCollection();

  return (
    <DocumentPage
      collection={games}
      render={(game) => (
        <Box display="flex" flexDirection="column" alignItems="center">
          <BigAvatarBox p={2}>
            <ColorAvatar color={game.avatarColor}>
              <Typography variant="h3">{game.avatarText}</Typography>
            </ColorAvatar>
          </BigAvatarBox>

          <Typography component="h1" variant="h6">
            {game.name}
          </Typography>
        </Box>
      )}
    />
  );
};

export default GamePage;
