import React, { FunctionComponent, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import {
  usePlaysCollection,
  Play,
  PlayerScores,
} from '../../context/PlaysCollectionProvider';
import { useGamesCollection } from '../../context/GamesCollectionProvider';
import { usePlayersCollection } from '../../context/PlayersCollectionProvider';
import {
  CircularProgress,
  Fab,
  Zoom,
  IconButton,
  Container,
  MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Header from '../Header';
import DocumentList from '../DocumentList';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useNavigate from '../../hooks/useNavigate';
import PlayerScoreListItem from './PlayerScoreListItem';
import DocumentActions from '../DocumentActions';
import Sheet from '../styles/Sheet';
import AddScoresSheet from './AddScoresSheet';
import useToggle from '../../hooks/useToggle';

const BottomRightFab: typeof Fab = styled(Fab)`
  position: fixed;
  right: ${({ theme }) => theme.spacing(2)}px;
  bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const PlayPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const playsCollection = usePlaysCollection();
  const gamesCollection = useGamesCollection();
  const playersCollection = usePlayersCollection();

  const statuses = [
    playsCollection.status,
    gamesCollection.status,
    playersCollection.status,
  ];

  const { id } = useParams();

  const play = playsCollection.get(id);
  const game = gamesCollection.get(play?.gameId);
  const players = playersCollection.getAll(play?.playerIds);

  const match = useRouteMatch(`/plays/${id}/adding-scores`);

  const [addingScores, toggleAddingScores] = useToggle(!!match);

  const startAddingScores = () => {
    toggleAddingScores();
    navigate.to('adding-scores');
  };

  const stopAddingScores = () => {
    toggleAddingScores();
    navigate.up();
  };

  useEffect(() => {
    if (play?.status === 'finished') {
      navigate.to('results', { replace: true });
    }
  }, [play, navigate]);

  const addScores = (playerScores: PlayerScores) => {
    const newPlay = new Play(play!.id, {
      ...play!.doc,
      rounds: [
        ...play!.doc.rounds,
        {
          scores: playerScores,
        },
      ],
    });

    let playOver = false;
    if (newPlay!.status === 'in-progress') {
      const winBy = play!.rules.winBy ?? 0;

      const reachedRoundLimit =
        play!.rules.playForRounds !== null &&
        newPlay.rounds.length >= play!.rules.playForRounds;

      const reachedScoreLimit =
        play!.rules.playToScore !== null &&
        newPlay.playerIds.some((id) => {
          const score = newPlay.getPlayerTotalScore(id);
          return (
            score >= play!.rules.playToScore! &&
            !newPlay.playerIds.some(
              (otherId) =>
                Math.abs(newPlay.getPlayerTotalScore(otherId) - score) < winBy
            )
          );
        });

      playOver = reachedRoundLimit || reachedScoreLimit;
    }

    playsCollection.set(newPlay);

    if (playOver) {
      navigate.push(`/plays/${id}/end-play`);
    }
  };

  return (
    <>
      <Header
        title={game && game.name}
        leftAction={
          <IconButton
            onClick={() => navigate.up()}
            size="small"
            aria-label={t('actions.back')}
          >
            <ArrowBackIcon />
          </IconButton>
        }
        rightAction={
          <DocumentActions>
            <MenuItem onClick={() => navigate.to('end-play')}>
              {t('play.end_play')}
            </MenuItem>
          </DocumentActions>
        }
      />

      <Container>
        {statuses.includes('loading') ? (
          <CircularProgress />
        ) : statuses.includes('error') ? (
          <div>error</div>
        ) : (
          <>
            <DocumentList
              documents={players}
              render={(player) => (
                <PlayerScoreListItem play={play!} player={player} />
              )}
            />

            <Zoom in>
              <BottomRightFab
                onClick={startAddingScores}
                aria-label={t('actions.add')}
                color="secondary"
              >
                <AddIcon />
              </BottomRightFab>
            </Zoom>
          </>
        )}
      </Container>

      <Sheet open={addingScores}>
        <AddScoresSheet onClose={stopAddingScores} onDone={addScores} />
      </Sheet>
    </>
  );
};

export default PlayPage;
