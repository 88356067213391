import React, { FunctionComponent } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import useMenu from '../hooks/useMenu';
import { useTranslation } from 'react-i18next';

interface DocumentActionsProps {
  disabled?: boolean;
}

const DocumentActions: FunctionComponent<DocumentActionsProps> = ({
  disabled = false,
  children,
}) => {
  const [anchorEl, open, toggleOpen] = useMenu();

  const { t } = useTranslation();

  return (
    <>
      <IconButton
        size="small"
        onClick={toggleOpen}
        disabled={disabled}
        aria-label={t('actions.more')}
      >
        <MoreIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={toggleOpen}
        onClick={() => toggleOpen()}
      >
        {children}
      </Menu>
    </>
  );
};

interface DocumentActionProps {
  onClick: () => void;
}

export const DeleteDocumentAction: FunctionComponent<DocumentActionProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();

  return <MenuItem onClick={onClick}>{t('actions.delete')}</MenuItem>;
};

export default DocumentActions;
