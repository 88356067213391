import React, { FunctionComponent } from 'react';
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import { useTranslation } from 'react-i18next';
import { GameRules } from '../../context/GamesCollectionProvider';
import NumberSetting from '../settings/NumberSetting';

interface EditGameRulesProps {
  gameRules: GameRules;
  onChange: (gameRules: GameRules) => void;
}

const EditGameRules: FunctionComponent<EditGameRulesProps> = ({
  gameRules,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <NumberSetting
        optional
        id="game-play-to-score"
        icon={<TrophyIcon />}
        label={t('game.play_to_score')}
        value={gameRules.playToScore}
        onChange={(value) => onChange({ ...gameRules, playToScore: value })}
      />

      <NumberSetting
        optional
        id="game-play-for-rounds"
        label={t('game.play_for_rounds')}
        value={gameRules.playForRounds}
        onChange={(value) => onChange({ ...gameRules, playForRounds: value })}
      />

      <NumberSetting
        optional
        id="game-win-by"
        label={t('game.win_by')}
        value={gameRules.winBy}
        onChange={(value) => onChange({ ...gameRules, winBy: value })}
      />
    </>
  );
};

export default EditGameRules;
