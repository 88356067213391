import React, { PropsWithChildren, useState } from 'react';
import Header from './Header';
import { IconButton, Typography, Container } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import TextIconButton from './TextIconButton';

interface CreateDocumentSheetProps<D> {
  title: string;
  initialDocumentData: D;
  isValid: (documentData: D) => boolean;
  onCancel: () => void;
  onSave: (documentData: D) => void;
  render: (
    documentData: D,
    setDocumentData: (documentData: D) => void
  ) => JSX.Element;
}

function CreateDocumentSheet<D>({
  title,
  initialDocumentData,
  isValid,
  onCancel,
  onSave,
  render,
}: PropsWithChildren<CreateDocumentSheetProps<D>>) {
  const { t } = useTranslation();
  const [documentData, setDocumentData] = useState(initialDocumentData);

  return (
    <>
      <Header
        title={title}
        leftAction={
          <IconButton
            onClick={onCancel}
            size="small"
            aria-label={t('actions.cancel')}
          >
            <CloseIcon />
          </IconButton>
        }
        rightAction={
          <TextIconButton
            color="primary"
            size="small"
            onClick={() => onSave(documentData)}
            disabled={!isValid(documentData)}
          >
            <Typography variant="overline">{t('actions.save')}</Typography>
          </TextIconButton>
        }
      />

      <Container>{render(documentData, setDocumentData)}</Container>
    </>
  );
}

export default CreateDocumentSheet;
