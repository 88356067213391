import { useState } from 'react';

const useMenu = (
  initialOpen = false
): [Element | null, boolean, (e?: any) => void] => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const [open, setOpen] = useState(initialOpen);
  const toggleOpen = (e?: any) => {
    if (e && e.currentTarget) {
      setAnchorEl(e.currentTarget as Element);
    }
    setOpen(!open);
  };

  return [anchorEl, open, toggleOpen];
};

export default useMenu;
