import { Drawer } from '@material-ui/core';
import styled from 'styled-components';
import withProps from '../../utils/withProps';

const Sheet = withProps(
  styled(Drawer)`
    .MuiDrawer-paper {
      height: 100%;
    }
  `,
  {
    anchor: 'bottom',
  }
);

export default Sheet;
