import React, { FunctionComponent, createContext, useContext } from 'react';
import useCollection, {
  Document,
  Collection,
  DocumentData,
} from '../hooks/useCollection';
import { GameRules } from './GamesCollectionProvider';

export type PlayerScores = { [key: string]: number };

export interface RoundData {
  scores: PlayerScores;
}

export interface PlayData extends DocumentData {
  ownerId: string;
  gameId: string;
  playerIds: string[];
  rounds: RoundData[];
  status: 'in-progress' | 'continued' | 'finished';
  rules: GameRules;
}

export class Play extends Document<PlayData> {
  public static isValid(data: PlayData) {
    return !!data.ownerId;
  }

  public get ownerId() {
    return this.data.ownerId;
  }

  public get gameId() {
    return this.data.gameId;
  }

  public get playerIds() {
    return this.data.playerIds;
  }

  public get rounds() {
    return this.data.rounds ?? [];
  }

  public get status() {
    return this.data.status;
  }

  public get rules() {
    return this.data.rules;
  }

  public get previousRound() {
    return this.rounds[this.rounds.length - 1];
  }

  public getPlayerPreviousRoundScore(playerId: string) {
    return this.previousRound?.scores?.[playerId] ?? 0;
  }

  public getPlayerTotalScore(playerId: string) {
    return this.rounds.reduce(
      (score, round) => score + (round.scores[playerId] ?? 0),
      0
    );
  }

  /**
   * @override
   */
  compareTo(other: Play) {
    const a = this.lastModified.seconds;
    const b = other.lastModified.seconds;

    if (a > b) return -1;
    if (a < b) return 1;

    // use default sorting as tie-breaker
    return super.compareTo(other);
  }
}

const PlaysCollectionContext = createContext<Collection<PlayData, Play> | null>(
  null
);

const PlaysCollectionProvider: FunctionComponent = ({ children }) => {
  const plays = useCollection('plays', Play);

  return (
    <PlaysCollectionContext.Provider value={plays}>
      {children}
    </PlaysCollectionContext.Provider>
  );
};

export const usePlaysCollection = () => {
  const plays = useContext(PlaysCollectionContext);

  if (!plays) {
    throw new Error(
      'Must use usePlaysCollection within PlaysCollectionProvider'
    );
  }

  return plays;
};

export default PlaysCollectionProvider;
