import React, { FunctionComponent } from 'react';
import { Player } from '../../context/PlayersCollectionProvider';
import {
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';
import ColorAvatar from '../ColorAvatar';
import { useTranslation } from 'react-i18next';
import { Play } from '../../context/PlaysCollectionProvider';

interface PlayerScoreListItemProps {
  play: Play;
  player: Player;
  showPreviousRound?: boolean;
}

const PlayerScoreListItem: FunctionComponent<PlayerScoreListItemProps> = ({
  play,
  player,
  showPreviousRound = true,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ListItemAvatar>
        <ColorAvatar color={player.avatarColor}>
          {player.avatarText}
        </ColorAvatar>
      </ListItemAvatar>
      <ListItemText
        primary={player.name}
        secondary={
          showPreviousRound &&
          t('play.previous_round_score', {
            score: play.getPlayerPreviousRoundScore(player.id),
          })
        }
      />
      <ListItemSecondaryAction>
        <Typography variant="subtitle2">
          {play.getPlayerTotalScore(player.id)}
        </Typography>
      </ListItemSecondaryAction>
    </>
  );
};

export default PlayerScoreListItem;
