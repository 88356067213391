import React, { FunctionComponent } from 'react';
import { TextField } from '@material-ui/core';
import Setting, { InputComponentProps, SettingComponentProps } from './Setting';

const TextInputComponent: FunctionComponent<InputComponentProps<string>> = ({
  id,
  value,
  onChange,
}) => {
  return (
    <TextField
      margin="dense"
      fullWidth
      autoFocus
      id={id}
      value={value ?? ''}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  );
};

const TextSetting: FunctionComponent<SettingComponentProps<string>> = (
  props
) => {
  return <Setting {...props} InputComponent={TextInputComponent} />;
};

export default TextSetting;
