import React, { FunctionComponent, useState } from 'react';
import Header from '../Header';
import { useTranslation } from 'react-i18next';
import {
  usePlaysCollection,
  PlayerScores,
} from '../../context/PlaysCollectionProvider';
import { usePlayersCollection } from '../../context/PlayersCollectionProvider';
import { useParams } from 'react-router-dom';
import {
  CircularProgress,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TextIconButton from '../TextIconButton';
import ScoreCarousel from './ScoreCarousel';

interface AddScoresSheetProps {
  onClose: () => void;
  onDone: (playerScores: PlayerScores) => void;
}

const AddScoresSheet: FunctionComponent<AddScoresSheetProps> = ({
  onClose,
  onDone,
}) => {
  const { t } = useTranslation();

  const playsCollection = usePlaysCollection();
  const playersCollection = usePlayersCollection();

  const statuses = [playsCollection.status, playersCollection.status];

  const { id } = useParams();

  const play = playsCollection.get(id);
  const players = playersCollection.getAll(play?.playerIds);

  const [playerScores, setPlayerScores] = useState<PlayerScores>({});

  const onScoreChanged = (playerId: string, score: number) => {
    setPlayerScores({
      ...playerScores,
      [playerId]: score,
    });
  };

  const onDoneInternal = () => {
    onClose();
    onDone(playerScores);
  };

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Header
        title={t('play.add_scores_header')}
        leftAction={
          <IconButton
            onClick={onClose}
            size="small"
            aria-label={t('actions.cancel')}
          >
            <CloseIcon />
          </IconButton>
        }
        rightAction={
          <TextIconButton color="primary" size="small" onClick={onDoneInternal}>
            <Typography variant="overline">{t('actions.done')}</Typography>
          </TextIconButton>
        }
      />

      {statuses.includes('loading') ? (
        <CircularProgress />
      ) : statuses.includes('error') ? (
        <div>error</div>
      ) : (
        <ScoreCarousel
          play={play!}
          players={players}
          playerScores={playerScores}
          onScoreChanged={onScoreChanged}
        />
      )}
    </Box>
  );
};

export default AddScoresSheet;
