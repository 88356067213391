import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListSubheader,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Document, DocumentData } from '../hooks/useCollection';
import styled from 'styled-components';

const StaticListSubheader = styled(ListSubheader)`
  position: static;
`;

const DocumentListSkeleton: FunctionComponent = () => {
  return (
    <List>
      {new Array(3).fill('').map((_, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            <Skeleton variant="circle" width="40px" height="40px" />
          </ListItemAvatar>
          <ListItemText>
            <Skeleton variant="text" />
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

const defaultCategory = 'undefined';

interface DocumentListProps<D extends DocumentData, T extends Document<D>> {
  documents: T[] | undefined;
  onDocumentClicked?: (document: T) => void;
  render: (document: T) => JSX.Element;
  categories?: Category<T>[];
}

const DocumentList = <D extends DocumentData, T extends Document<D>>({
  documents,
  onDocumentClicked,
  render,
  categories = [{ predicate: Boolean }],
}: PropsWithChildren<DocumentListProps<D, T>>) => {
  if (!documents) {
    return <DocumentListSkeleton />;
  }

  const categoryLists: Record<string, T[]> = {};

  categories.forEach(({ title = defaultCategory, predicate }) => {
    categoryLists[title] = [];
    documents.forEach((document) => {
      if (predicate(document)) {
        categoryLists[title].push(document);
      }
    });
  });

  return (
    <List>
      {categories.map(
        ({ title = defaultCategory }) =>
          categoryLists[title].length > 0 && (
            <React.Fragment key={title}>
              {title !== defaultCategory && (
                <StaticListSubheader>{title}</StaticListSubheader>
              )}
              {categoryLists[title].map((document) =>
                onDocumentClicked ? (
                  <ListItem
                    key={document.id}
                    button
                    onClick={() => onDocumentClicked(document)}
                  >
                    {render(document)}
                  </ListItem>
                ) : (
                  <ListItem key={document.id}>{render(document)}</ListItem>
                )
              )}
            </React.Fragment>
          )
      )}
    </List>
  );
};

export default DocumentList;
