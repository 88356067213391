import { List } from '@material-ui/core';
import styled from 'styled-components';

const SettingsList = styled(List)`
  .MuiListItemIcon-root {
    min-width: ${({ theme }) => theme.spacing(3) + theme.spacing(2)}px;
  }
`;

export default SettingsList;
