import React, { FunctionComponent } from 'react';
import { ListItem } from '@material-ui/core';
import GameIcon from '@material-ui/icons/VideogameAsset';
import GolfIcon from '@material-ui/icons/GolfCourse';
import PlayersIcon from '@material-ui/icons/People';
import ColorPicker from '../ColorPicker';
import { useTranslation } from 'react-i18next';
import { GameData } from '../../context/GamesCollectionProvider';
import SettingsList from '../styles/SettingsList';
import TextSetting from '../settings/TextSetting';
import NumberRangeSetting from '../settings/NumberRangeSetting';
import BooleanSetting from '../settings/BooleanSetting';
import SettingsListSkeleton from '../settings/SettingsListSkeleton';
import EditGameRules from './EditGameRules';

interface EditGameProps {
  gameData?: GameData;
  onChange: (gameData: GameData) => void;
}

const EditGame: FunctionComponent<EditGameProps> = ({ gameData, onChange }) => {
  const { t } = useTranslation();

  if (!gameData) {
    return <SettingsListSkeleton colorPicker settingsCount={8} />;
  }

  return (
    <SettingsList>
      <ListItem>
        <ColorPicker
          label={t('game.avatar_color')}
          color={gameData.avatarColor}
          onChange={(color) => onChange({ ...gameData, avatarColor: color })}
        />
      </ListItem>

      <TextSetting
        id="game-name"
        icon={<GameIcon />}
        label={t('game.name')}
        value={gameData.name}
        onChange={(value) => onChange({ ...gameData, name: value! })}
      />

      <NumberRangeSetting
        id="game-player-count"
        icon={<PlayersIcon />}
        label={t('game.player_count')}
        value={gameData.playerCount}
        onChange={(value) => onChange({ ...gameData, playerCount: value! })}
      />

      <BooleanSetting
        id="game-golf-rules"
        icon={<GolfIcon />}
        label={t('game.golf_rules')}
        onDescription={t('game.golf_rules_on_description')}
        offDescription={t('game.golf_rules_off_description')}
        value={gameData.golfRules}
        onChange={(value) => onChange({ ...gameData, golfRules: value! })}
      />

      {gameData.roundBased && (
        <EditGameRules
          gameRules={gameData}
          onChange={(gameRules) => onChange({ ...gameData, ...gameRules })}
        />
      )}
    </SettingsList>
  );
};

export default EditGame;
