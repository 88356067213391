import React from 'react';
import { useTheme, Theme } from '@material-ui/core';
import styled, { ThemeProvider } from 'styled-components';

interface ExtendedTheme extends Theme {
  gap: number;
  reverseGap: number;
}

const Container = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: ${(p) => (p.theme as ExtendedTheme).gap}px;
    margin-left: ${(p) => (p.theme as ExtendedTheme).reverseGap}px;
  }
`;

interface InlineProps {
  gap?: number;
  reverse?: boolean;
  mainAlign?: 'unset' | 'flex-start' | 'center' | 'flex-end';
  crossAlign?: 'unset' | 'flex-start' | 'center' | 'flex-end';
  className?: string;
}

const Inline: React.FunctionComponent<InlineProps> = ({
  children,
  gap = 0,
  reverse = false,
  mainAlign = 'unset',
  crossAlign = 'unset',
  className,
}) => {
  const theme = useTheme();
  const themeGap = useTheme().spacing(gap);

  const direction = reverse ? 'row-reverse' : 'row';

  return (
    <ThemeProvider
      theme={
        {
          ...theme,
          reverseGap: reverse ? themeGap : 0,
          gap: !reverse ? themeGap : 0,
        } as any
      }
    >
      <Container
        className={className}
        style={{
          flexDirection: direction,
          alignItems: crossAlign,
          justifyContent: mainAlign,
        }}
      >
        {children}
      </Container>
    </ThemeProvider>
  );
};

export default Inline;
