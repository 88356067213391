import React, { FunctionComponent } from 'react';
import {
  usePlayersCollection,
  Player,
  PlayerData,
} from '../../context/PlayersCollectionProvider';
import { useParams } from 'react-router-dom';
import EditPlayer from './EditPlayer';
import { useTranslation } from 'react-i18next';
import useNavigate from '../../hooks/useNavigate';
import EditDocumentPage from '../EditDocumentPage';

const EditPlayerPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const players = usePlayersCollection();

  const { t } = useTranslation();

  const onSave = (playerData: PlayerData) => {
    players.set(new Player(id, playerData));
    navigate.up();
  };

  const onDelete = () => {
    players.delete(id);
    navigate.push('/players');
  };

  return (
    <EditDocumentPage
      title={t('edit_player_title')}
      collection={players}
      isValid={Player.isValid}
      onSave={onSave}
      onDelete={onDelete}
      render={(setPlayerData, playerData) => (
        <EditPlayer playerData={playerData} onChange={setPlayerData} />
      )}
    />
  );
};

export default EditPlayerPage;
