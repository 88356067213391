import React, { FunctionComponent } from 'react';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGamesCollection } from '../../context/GamesCollectionProvider';
import DocumentListPage from '../DocumentListPage';
import ColorAvatar from '../ColorAvatar';

const GamesListPage: FunctionComponent = () => {
  const games = useGamesCollection();
  const { t } = useTranslation();

  return (
    <DocumentListPage
      title={t('games_title')}
      collection={games}
      emptyListMessage={t('game.empty_list')}
      render={(game) => (
        <>
          <ListItemAvatar>
            <ColorAvatar color={game.avatarColor}>
              {game.avatarText}
            </ColorAvatar>
          </ListItemAvatar>
          <ListItemText>{game.name}</ListItemText>
        </>
      )}
    />
  );
};

export default GamesListPage;
