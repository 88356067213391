import React, { FunctionComponent } from 'react';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import ColorAvatar from '../ColorAvatar';
import { Game } from '../../context/GamesCollectionProvider';
import { Play } from '../../context/PlaysCollectionProvider';
import { useTranslation } from 'react-i18next';

interface PlayListItemProps {
  play: Play;
  game: Game;
}

const PlayListItem: FunctionComponent<PlayListItemProps> = ({ play, game }) => {
  const { i18n } = useTranslation();

  const thisYear = new Date().getFullYear();
  const date = play.lastModified.toDate();
  const dateString = new Intl.DateTimeFormat(i18n.language, {
    year: date.getFullYear() !== thisYear ? 'numeric' : undefined,
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  }).format(date);

  return (
    <>
      <ListItemAvatar>
        <ColorAvatar color={game?.avatarColor}>{game?.avatarText}</ColorAvatar>
      </ListItemAvatar>
      <ListItemText primary={game?.name} secondary={dateString} />
    </>
  );
};

export default PlayListItem;
