import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import PlayersListPage from './PlayersListPage';
import PlayerPage from './PlayerPage';
import CreatePlayerPage from './CreatePlayerPage';
import EditPlayerPage from './EditPlayerPage';

const PlayersRouter: FunctionComponent = () => {
  return (
    <Switch>
      <Route path="/players" exact component={PlayersListPage} />
      <Route path="/players/new" component={CreatePlayerPage} />
      <Route path="/players/:id" exact component={PlayerPage} />
      <Route path="/players/:id/edit" component={EditPlayerPage} />
    </Switch>
  );
};

export default PlayersRouter;
