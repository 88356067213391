import React, { FunctionComponent, useState } from 'react';
import PlayerScoreListItem from './PlayerScoreListItem';
import { Play, PlayerScores } from '../../context/PlaysCollectionProvider';
import { Player } from '../../context/PlayersCollectionProvider';
import { List, ListItem, Typography, Box } from '@material-ui/core';
import { useDrag } from 'react-use-gesture';
import styled from 'styled-components';
import NumberPad from './NumberPad';

const CarouselDot = styled.span`
  border-radius: 100%;
  width: 4px;
  height: 4px;
  transition: 0.2s ease-in-out;
  opacity: 0.5;
  background-color: ${({ theme }) => theme.palette.text.primary};
  margin: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(0.5)}px`};

  &.selected {
    transform: scale(1.5);
    opacity: 1;
  }
`;

const Carousel = styled(Box)`
  overflow-x: hidden;
`;

const CarouselItem = styled(Box)`
  min-width: 100vw;
  transition: transform 0.2s ease-in-out;
`;

interface ScoreCarouselProps {
  play: Play;
  players: Player[];
  playerScores: PlayerScores;
  onScoreChanged: (playerId: string, score: number) => void;
}

const ScoreCarousel: FunctionComponent<ScoreCarouselProps> = ({
  play,
  players,
  playerScores,
  onScoreChanged,
}) => {
  const [index, setIndex] = useState(0);

  const [scoreDisplays, setScoreDisplays] = useState<
    Record<string, string | undefined>
  >({});

  const player = players[index];
  const isFinal = index === play.playerIds.length - 1;
  const score = playerScores[player.id] ?? 0;
  const scoreDisplay = scoreDisplays[player.id];

  const onDisplayChanged = (display?: string) => {
    setScoreDisplays({ ...scoreDisplays, [player.id]: display });
  };

  const nextPlayer = () => {
    setIndex((index + 1) % players.length);
  };

  const previousPlayer = () => {
    setIndex(index === 0 ? players.length - 1 : index - 1);
  };

  const bind = useDrag(({ swipe: [x] }) => {
    if (x > 0) {
      previousPlayer();
    } else if (x < 0) {
      nextPlayer();
    }
  });

  return (
    <>
      <Carousel display="flex" flexGrow={1} {...bind()}>
        {players.map((p) => (
          <CarouselItem
            key={p.id}
            display="flex"
            flexDirection="column"
            flexGrow={1}
            style={{
              transform: `translate(${-100 * index}vw, 0)`,
            }}
          >
            <List>
              <ListItem>
                <PlayerScoreListItem play={play} player={p} />
              </ListItem>
            </List>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexGrow={1}
            >
              <Typography variant="h4">{scoreDisplays[p.id] ?? '0'}</Typography>
            </Box>
          </CarouselItem>
        ))}
      </Carousel>

      <Box display="flex" justifyContent="center">
        {players.map((p) => (
          <CarouselDot
            key={p.id}
            className={p.id === player.id ? 'selected' : undefined}
          />
        ))}
      </Box>

      <NumberPad
        value={score}
        onChange={(value) => onScoreChanged(player.id, value)}
        display={scoreDisplay}
        onDisplayChange={onDisplayChanged}
        onNextClicked={nextPlayer}
        isFinal={isFinal}
      />
    </>
  );
};

export default ScoreCarousel;
