import React, { FunctionComponent } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import GameIcon from '@material-ui/icons/VideogameAsset';
import styled from 'styled-components';
import PlayersRouter from './players/PlayersRouter';
import { useTranslation } from 'react-i18next';
import GamesRouter from './games/GamesRouter';
import useNavigate from '../hooks/useNavigate';
import PlaysRouter from './plays/PlaysRouter';

const StyledBottomNavigation = styled(BottomNavigation)`
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -1px 1px -1px rgba(0, 0, 0, 0.2),
    0px -1px 1px 0px rgba(0, 0, 0, 0.14);
`;

const Nav: FunctionComponent = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  // Only show the nav on the root pages
  const hasNav = useRouteMatch({
    exact: true,
    path: ['/players', '/plays', '/games'],
  });

  if (!hasNav) {
    return null;
  }

  return (
    <Box mt={7}>
      <StyledBottomNavigation
        showLabels
        value={navigate.path}
        onChange={(_, value) => navigate.push(value)}
      >
        <BottomNavigationAction
          value="/plays"
          label={t('plays_title')}
          icon={<PlayArrowIcon />}
        />
        <BottomNavigationAction
          value="/games"
          label={t('games_title')}
          icon={<GameIcon />}
        />
        <BottomNavigationAction
          value="/players"
          label={t('players_title')}
          icon={<PeopleIcon />}
        />
      </StyledBottomNavigation>
    </Box>
  );
};

const AuthenticatedApp: FunctionComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/plays" />
        </Route>

        <Route path="/plays" component={PlaysRouter} />
        <Route path="/games" component={GamesRouter} />
        <Route path="/players" component={PlayersRouter} />
      </Switch>

      <Nav />
    </BrowserRouter>
  );
};

export default AuthenticatedApp;
