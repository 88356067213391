import React, { FunctionComponent } from 'react';
import useNavigate from '../../hooks/useNavigate';
import CreateGameSheet from './CreateGameSheet';

const CreateGamePage: FunctionComponent = () => {
  const navigate = useNavigate();

  return <CreateGameSheet onClose={() => navigate.up()} />;
};

export default CreateGamePage;
