import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import EditGame from './EditGame';
import { useTranslation } from 'react-i18next';
import {
  useGamesCollection,
  Game,
  GameData,
} from '../../context/GamesCollectionProvider';
import useNavigate from '../../hooks/useNavigate';
import EditDocumentPage from '../EditDocumentPage';

const EditGamePage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const games = useGamesCollection();

  const { t } = useTranslation();

  const onSave = (gameData: GameData) => {
    games.set(new Game(id, gameData));
    navigate.up();
  };

  const onDelete = () => {
    games.delete(id);
    navigate.push('/games');
  };

  return (
    <EditDocumentPage
      title={t('edit_game_title')}
      collection={games}
      isValid={Game.isValid}
      onSave={onSave}
      onDelete={onDelete}
      render={(setGameData, gameData) => (
        <EditGame gameData={gameData} onChange={setGameData} />
      )}
    />
  );
};

export default EditGamePage;
