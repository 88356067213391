import React, { FunctionComponent } from 'react';
import { useAuth } from '../../context/AuthProvider';
import { randomAvatarColor } from '../../utils/avatarColors';
import EditGame from './EditGame';
import { useTranslation } from 'react-i18next';
import {
  Game,
  useGamesCollection,
  GameData,
} from '../../context/GamesCollectionProvider';
import CreateDocumentSheet from '../CreateDocumentSheet';

interface CreateGameSheetProps {
  onClose: () => void;
  onSave?: (id: string) => void;
}

const CreateGameSheet: FunctionComponent<CreateGameSheetProps> = ({
  onClose,
  onSave,
}) => {
  const [user] = useAuth();
  const games = useGamesCollection();

  const { t } = useTranslation();

  const onSaveInternal = async (gameData: GameData) => {
    onClose();
    const id = await games.add(gameData);
    if (onSave) {
      onSave(id);
    }
  };

  return (
    <CreateDocumentSheet
      title={t('create_game_title')}
      initialDocumentData={
        {
          avatarColor: randomAvatarColor(),
          name: 'New game',
          ownerId: user.uid,
          playerCount: {
            min: 2,
            max: 4,
          },
          golfRules: false,
          roundBased: true,
          roundTimer: null,
          playToScore: null,
          playForRounds: null,
          winBy: null,
        } as GameData
      }
      isValid={Game.isValid}
      onSave={onSaveInternal}
      onCancel={onClose}
      render={(gameData, setGameData) => (
        <EditGame gameData={gameData} onChange={setGameData} />
      )}
    />
  );
};

export default CreateGameSheet;
