import React, { FunctionComponent } from 'react';
import { ListItem } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import ColorPicker from '../ColorPicker';
import { useTranslation } from 'react-i18next';
import { PlayerData } from '../../context/PlayersCollectionProvider';
import SettingsList from '../styles/SettingsList';
import TextSetting from '../settings/TextSetting';
import SettingsListSkeleton from '../settings/SettingsListSkeleton';

interface EditPlayerProps {
  playerData?: PlayerData;
  onChange: (playerData: PlayerData) => void;
}

const EditPlayer: FunctionComponent<EditPlayerProps> = ({
  playerData,
  onChange,
}) => {
  const { t } = useTranslation();

  if (!playerData) {
    return <SettingsListSkeleton colorPicker settingsCount={1} />;
  }

  return (
    <SettingsList>
      <ListItem>
        <ColorPicker
          label={t('player.avatar_color')}
          color={playerData.avatarColor}
          onChange={(color) => onChange({ ...playerData, avatarColor: color })}
        />
      </ListItem>

      <TextSetting
        id="player-name"
        icon={<PersonIcon />}
        label={t('player.name')}
        value={playerData.name}
        onChange={(value) => onChange({ ...playerData, name: value! })}
      />
    </SettingsList>
  );
};

export default EditPlayer;
