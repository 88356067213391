import React, { FunctionComponent } from 'react';
import { TextField } from '@material-ui/core';
import Setting, { InputComponentProps, SettingComponentProps } from './Setting';
import Inline from '../layout/Inline';
import { useTranslation } from 'react-i18next';

const NumberRangeInputComponent: FunctionComponent<InputComponentProps<
  NumberRange
>> = ({ id, value, onChange }) => {
  const { t } = useTranslation();

  return (
    <Inline gap={2}>
      <TextField
        margin="dense"
        label={t('settings.min')}
        fullWidth
        autoFocus
        type="number"
        id={`${id}-min`}
        value={value?.min ?? ''}
        onChange={(e) =>
          onChange({
            min: +e.currentTarget.value,
            max: value?.max ?? +e.currentTarget.value,
          })
        }
      />

      <TextField
        margin="dense"
        label={t('settings.max')}
        fullWidth
        type="number"
        id={`${id}-max`}
        value={value?.max ?? ''}
        onChange={(e) =>
          onChange({
            max: +e.currentTarget.value,
            min: value?.min ?? +e.currentTarget.value,
          })
        }
      />
    </Inline>
  );
};

const NumberRangeSetting: FunctionComponent<SettingComponentProps<
  NumberRange
>> = (props) => {
  return (
    <Setting
      {...props}
      valueFormatter={(value) => `${value.min} to ${value.max}`}
      InputComponent={NumberRangeInputComponent}
    />
  );
};

export default NumberRangeSetting;
