import React, { useReducer } from 'react';
import { FunctionComponent } from 'react';
import Header from '../Header';
import {
  ListItem,
  IconButton,
  Stepper,
  Step,
  StepContent,
  StepButton,
  ListItemAvatar,
  ListItemText,
  Fab,
  Box,
  Zoom,
  CircularProgress,
  Typography,
  ListItemIcon,
  Container,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddGameIcon from '@material-ui/icons/Add';
import AddPlayerIcon from '@material-ui/icons/PersonAdd';
import SelectedIcon from '@material-ui/icons/Done';
import PlayIcon from '@material-ui/icons/PlayArrow';
import useNavigate from '../../hooks/useNavigate';
import { useTranslation } from 'react-i18next';
import DocumentList from '../DocumentList';
import {
  useGamesCollection,
  Game,
  GameRules,
} from '../../context/GamesCollectionProvider';
import ColorAvatar from '../ColorAvatar';
import {
  usePlayersCollection,
  Player,
} from '../../context/PlayersCollectionProvider';
import { usePlaysCollection } from '../../context/PlaysCollectionProvider';
import { useAuth } from '../../context/AuthProvider';
import SettingsList from '../styles/SettingsList';
import EditGameRules from '../games/EditGameRules';
import styled from 'styled-components';
import CreatePlayerSheet from '../players/CreatePlayerSheet';
import useToggle from '../../hooks/useToggle';
import CreateGameSheet from '../games/CreateGameSheet';
import { useRouteMatch } from 'react-router-dom';
import Sheet from '../styles/Sheet';

const FabContainer = styled(Box)`
  pointer-events: none;

  .MuiFab-root {
    pointer-events: all;
  }
`;

interface State {
  step: number;
  game?: Game;
  rules?: GameRules;
  playerIds: string[];
  loading: boolean;
}

type Action =
  | PayloadAction<'setStep', number>
  | EmptyAction<'nextStep'>
  | PayloadAction<'selectGame', Game>
  | EmptyAction<'deselectGame'>
  | PayloadAction<'setRules', GameRules>
  | PayloadAction<'selectPlayer', string>
  | PayloadAction<'deselectPlayer', string>
  | EmptyAction<'startGame'>;

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setStep':
      return { ...state, step: action.payload };
    case 'nextStep':
      return { ...state, step: state.step + 1 };
    case 'selectGame':
      return {
        ...state,
        game: action.payload,
        rules: action.payload.rules,
        step: state.step + 1,
      };
    case 'deselectGame':
      return { ...state, game: undefined, rules: undefined };
    case 'setRules':
      return { ...state, rules: action.payload };
    case 'selectPlayer':
      return { ...state, playerIds: [...state.playerIds, action.payload] };
    case 'deselectPlayer':
      return {
        ...state,
        playerIds: state.playerIds.filter((id) => id !== action.payload),
      };
    case 'startGame':
      return {
        ...state,
        loading: true,
      };
  }
};

const CreatePlayPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const [user] = useAuth();
  const plays = usePlaysCollection();

  const { t } = useTranslation();

  const [state, send] = useReducer(reducer, {
    step: 0,
    game: undefined,
    rules: undefined,
    playerIds: [],
    loading: false,
  } as State);

  const steps = [
    {
      label: 'Game',
      render: () => <GameStep state={state} send={send} />,
    },
    {
      label: 'Rules',
      render: () => <RulesStep state={state} send={send} />,
    },
    {
      label: 'Players',
      render: () => <PlayersStep state={state} send={send} />,
    },
  ];

  const canStart =
    state.game &&
    state.playerIds.length >= state.game.playerCount.min &&
    state.playerIds.length <= state.game.playerCount.max;

  const startGame = async () => {
    if (!canStart) {
      return;
    }

    send({ type: 'startGame' });

    const id = await plays.add({
      ownerId: user.uid,
      gameId: state.game!.id,
      playerIds: state.playerIds,
      rounds: [],
      status: 'in-progress',
      rules: state.rules!,
    });

    navigate.replace(`/plays/${id}`);
  };

  if (state.loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Header
        title={t('plays_title')}
        leftAction={
          <IconButton
            onClick={() => navigate.up()}
            size="small"
            aria-label={t('actions.cancel')}
          >
            <CloseIcon />
          </IconButton>
        }
      />

      <Container>
        <Stepper nonLinear orientation="vertical" activeStep={state.step}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepButton
                onClick={() => send({ type: 'setStep', payload: index })}
              >
                {step.label}
              </StepButton>
              <StepContent>{step.render()}</StepContent>
            </Step>
          ))}
        </Stepper>
      </Container>

      <FabContainer mt={6}>
        <Box
          position="fixed"
          bottom={0}
          mb={2}
          width="100%"
          display="flex"
          justifyContent="center"
        >
          <Zoom in={canStart}>
            <Fab variant="extended" color="secondary" onClick={startGame}>
              <PlayIcon />
              <Box ml={1}>{t('play.start')}</Box>
            </Fab>
          </Zoom>
        </Box>
      </FabContainer>
    </>
  );
};

const CreateListItem = styled(ListItem)`
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: ${({ theme }) => -theme.spacing(1)}px;

  .MuiListItemIcon-root {
    color: inherit;
  }
`;

interface StepProps {
  state: State;
  send: (action: Action) => void;
}

const GameStep: FunctionComponent<StepProps> = ({ state, send }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const games = useGamesCollection();

  const match = useRouteMatch('/plays/new/create-game');

  const [creating, toggleCreating] = useToggle(!!match);

  const startCreating = () => {
    toggleCreating();
    navigate.to('create-game');
  };

  const stopCreating = () => {
    toggleCreating();
    navigate.up();
  };

  const isSelected = (game: Game) => state.game?.id === game.id;

  const onGameClicked = (game: Game) => {
    if (isSelected(game)) {
      send({ type: 'deselectGame' });
    } else {
      send({ type: 'selectGame', payload: game });
    }
  };

  return (
    <>
      <CreateListItem button onClick={startCreating}>
        <ListItemIcon>
          <AddGameIcon />
        </ListItemIcon>
        <ListItemText>{t('play.create_game')}</ListItemText>
      </CreateListItem>

      <DocumentList
        documents={games.data}
        onDocumentClicked={onGameClicked}
        render={(game) => (
          <>
            <ListItemAvatar>
              {isSelected(game) ? (
                <ColorAvatar color="themePrimaryColor">
                  <SelectedIcon />
                </ColorAvatar>
              ) : (
                <ColorAvatar color={game.avatarColor}>
                  {game.avatarText}
                </ColorAvatar>
              )}
            </ListItemAvatar>
            <ListItemText>{game.name}</ListItemText>
          </>
        )}
      />

      <Sheet open={creating}>
        <CreateGameSheet onClose={stopCreating} />
      </Sheet>
    </>
  );
};

const RulesStep: FunctionComponent<StepProps> = ({ state, send }) => {
  const { t } = useTranslation();

  if (!state.game || !state.rules) {
    return <Typography>{t('play.select_game_first')}</Typography>;
  }

  return (
    <SettingsList>
      {state.game.roundBased && (
        <EditGameRules
          gameRules={state.rules}
          onChange={(gameRules) =>
            send({ type: 'setRules', payload: gameRules })
          }
        />
      )}
    </SettingsList>
  );
};

const PlayersStep: FunctionComponent<StepProps> = ({ state, send }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const players = usePlayersCollection();

  const match = useRouteMatch('/plays/new/create-player');

  const [creating, toggleCreating] = useToggle(!!match);

  const startCreating = () => {
    toggleCreating();
    navigate.to('create-player');
  };

  const stopCreating = () => {
    toggleCreating();
    navigate.up();
  };

  const isSelected = (player: Player) => state.playerIds.includes(player.id);

  const onPlayerClicked = (player: Player) => {
    if (isSelected(player)) {
      send({ type: 'deselectPlayer', payload: player.id });
    } else {
      send({ type: 'selectPlayer', payload: player.id });
    }
  };

  const onPlayerCreated = (id: string) => {
    send({ type: 'selectPlayer', payload: id });
  };

  return (
    <>
      <CreateListItem button onClick={startCreating}>
        <ListItemIcon>
          <AddPlayerIcon />
        </ListItemIcon>
        <ListItemText>{t('play.create_player')}</ListItemText>
      </CreateListItem>

      <DocumentList
        documents={players.data}
        onDocumentClicked={onPlayerClicked}
        render={(player) => (
          <>
            <ListItemAvatar>
              {isSelected(player) ? (
                <ColorAvatar color="themePrimaryColor">
                  <SelectedIcon />
                </ColorAvatar>
              ) : (
                <ColorAvatar color={player.avatarColor}>
                  {player.avatarText}
                </ColorAvatar>
              )}
            </ListItemAvatar>
            <ListItemText>{player.name}</ListItemText>
          </>
        )}
      />

      <Sheet open={creating}>
        <CreatePlayerSheet onClose={stopCreating} onSave={onPlayerCreated} />
      </Sheet>
    </>
  );
};

export default CreatePlayPage;
