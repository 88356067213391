import React, { FunctionComponent } from 'react';
import useCurrentPlay from '../../hooks/useCurrentPlay';
import {
  CircularProgress,
  List,
  ListItem,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  ListItemText,
  ListItemIcon,
  Snackbar,
  Container,
  Paper,
} from '@material-ui/core';
import TrophyIcon from '@material-ui/icons/EmojiEvents';
import ShareIcon from '@material-ui/icons/Share';
import RematchIcon from '@material-ui/icons/Refresh';
import HomeIcon from '@material-ui/icons/Home';
import { useTranslation } from 'react-i18next';
import PlayerScoreListItem from './PlayerScoreListItem';
import BottomPaper from '../styles/BottomPaper';
import styled from 'styled-components';
import useNavigate from '../../hooks/useNavigate';
import { Play } from '../../context/PlaysCollectionProvider';
import useToggle from '../../hooks/useToggle';
import PlayListItem from './PlayListItem';
import { getWinnerIds, getPlayerIdsByScore } from '../../utils/playHelpers';

const WinnerIcon = styled(TrophyIcon)`
  font-size: ${({ theme }) => theme.typography.h1.fontSize};
`;

const StyledSnackbar = styled(Snackbar)`
  margin-bottom: ${({ theme }) => theme.spacing(2 * 2 + 4.5)}px;
`;

const EndPlayPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [endPlayDialogOpen, toggleEndPlayDialog] = useToggle();
  const [snackbarOpen, toggleSnackbar] = useToggle();

  const {
    status,
    play,
    game,
    playsCollection,
    playersCollection,
  } = useCurrentPlay();

  const winnerIds = play && game && getWinnerIds(play, game.golfRules);
  const winnerId = winnerIds?.length === 1 && winnerIds[0];

  const players = playersCollection.getAll(
    play && game && getPlayerIdsByScore(play, game.golfRules)
  );

  const onShareClicked = async () => {
    // TODO: Use new web share API where available
    await navigator.clipboard.writeText(
      `${window.location.host}/plays/${play!.id}/results`
    );
    toggleSnackbar();
  };

  const onKeepPlayingClicked = () => {
    playsCollection.set(
      new Play(play!.id, {
        ...play!.doc,
        status: 'continued',
      })
    );
    navigate.up();
  };

  const onEndPlayDialogResult = async (result: EndPlayDialogResult) => {
    playsCollection.set(
      new Play(play!.id, {
        ...play!.doc,
        status: 'finished',
      })
    );
    if (result === 'rematch') {
      // TODO: Loading spinner?
      const id = await playsCollection.add({
        gameId: play!.gameId,
        playerIds: play!.playerIds,
        ownerId: play!.ownerId,
        rounds: [],
        status: 'in-progress',
        rules: {
          ...play!.rules,
        },
      });
      navigate.push(`/plays/${id}`);
    } else if (result === 'home') {
      navigate.push('/');
    }
  };

  if (status === 'error') {
    return <div>error</div>;
  }

  if (status === 'loading') {
    return <CircularProgress />;
  }

  return (
    <>
      <Paper>
        <ListItem>
          <PlayListItem play={play!} game={game!} />
        </ListItem>
      </Paper>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={2}
        mb={1.5}
      >
        <WinnerIcon color="secondary" />

        <Typography variant="h5" component="h1">
          {winnerId
            ? t('play.player_won', playersCollection.get(winnerId)!.doc)
            : t('play.tie')}
        </Typography>

        <Box mt={1}>
          <Button
            color="primary"
            startIcon={<ShareIcon />}
            onClick={onShareClicked}
          >
            Share results
          </Button>
        </Box>
      </Box>

      <Container>
        <List>
          {players.map((player) => (
            <ListItem key={player.id}>
              <PlayerScoreListItem
                play={play!}
                player={player}
                showPreviousRound={false}
              />
            </ListItem>
          ))}
        </List>
      </Container>

      <StyledSnackbar
        open={snackbarOpen}
        onClose={toggleSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message="Link copied to clipboard"
        autoHideDuration={4000}
      />

      <Box mt={2 * 2 + 4.5}>
        <Box position="fixed" bottom={0} width="100%">
          <BottomPaper>
            <Box py={2} display="flex" justifyContent="space-evenly">
              <Button color="primary" onClick={onKeepPlayingClicked}>
                {t('play.keep_playing')}
              </Button>
              <Button color="primary" onClick={toggleEndPlayDialog}>
                {t('play.end_play')}
              </Button>
            </Box>
          </BottomPaper>
        </Box>
      </Box>

      <EndPlayDialog
        open={endPlayDialogOpen}
        onClose={toggleEndPlayDialog}
        onResult={onEndPlayDialogResult}
      />
    </>
  );
};

type EndPlayDialogResult = 'rematch' | 'home';

interface EndPlayDialogProps {
  open: boolean;
  onClose: () => void;
  onResult: (result: EndPlayDialogResult) => void;
}

const EndPlayDialog: FunctionComponent<EndPlayDialogProps> = ({
  open,
  onClose,
  onResult,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('play.whats_next')}</DialogTitle>
      <List>
        <ListItem button onClick={() => onResult('rematch')}>
          <ListItemIcon>
            <RematchIcon />
          </ListItemIcon>
          <ListItemText>{t('play.rematch')}</ListItemText>
        </ListItem>
        <ListItem button onClick={() => onResult('home')}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>{t('play.home')}</ListItemText>
        </ListItem>
      </List>
    </Dialog>
  );
};

export default EndPlayPage;
