import React, { useState, useEffect, PropsWithChildren } from 'react';
import Header from './Header';
import { IconButton, Typography, Container } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useNavigate from '../hooks/useNavigate';
import { Document, Collection, DocumentData } from '../hooks/useCollection';
import DocumentActions, { DeleteDocumentAction } from './DocumentActions';
import TextIconButton from './TextIconButton';

interface EditDocumentPageProps<D extends DocumentData, T extends Document<D>> {
  title: string;
  isValid: (documentData: D) => boolean;
  onSave: (documentData: D) => void;
  onDelete: () => void;
  render: (
    setDocumentData: (documentData: D) => void,
    documentData?: D
  ) => JSX.Element;
  collection: Collection<D, T>;
}

function EditDocumentPage<D extends DocumentData, T extends Document<D>>({
  title,
  isValid,
  onSave,
  onDelete,
  render,
  collection,
}: PropsWithChildren<EditDocumentPageProps<D, T>>) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const document =
    collection.status === 'success' ? collection.get(id) : undefined;

  const [documentData, setDocumentData] = useState(document?.doc);

  useEffect(() => {
    if (document) {
      setDocumentData(document.doc);
    }
  }, [document]);

  return (
    <>
      <Header
        title={title}
        leftAction={
          <IconButton
            onClick={() => navigate.up()}
            size="small"
            aria-label={t('actions.cancel')}
          >
            <CloseIcon />
          </IconButton>
        }
        rightAction={
          <>
            <DocumentActions disabled={!documentData}>
              <DeleteDocumentAction onClick={onDelete} />
            </DocumentActions>
            <TextIconButton
              color="primary"
              size="small"
              onClick={() => documentData && onSave(documentData)}
              disabled={!documentData || !isValid(documentData)}
            >
              <Typography variant="overline">{t('actions.save')}</Typography>
            </TextIconButton>
          </>
        }
      />

      <Container>
        {collection.status === 'error' ? (
          <div>{collection.error.message}</div>
        ) : (
          render(setDocumentData, documentData)
        )}
      </Container>
    </>
  );
}

export default EditDocumentPage;
