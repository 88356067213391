import React, { FunctionComponent, createContext, useContext } from 'react';
import useCollection, {
  Document,
  Collection,
  DocumentData,
} from '../hooks/useCollection';

export interface PlayerData extends DocumentData {
  name: string;
  avatarColor: string;
  ownerId: string;
}

export class Player extends Document<PlayerData> {
  public static isValid(data: PlayerData) {
    return !!data.name.trim() && !!data.avatarColor && !!data.ownerId;
  }

  public get name() {
    return this.data.name;
  }

  public get avatarColor() {
    return this.data.avatarColor;
  }

  public get ownerId() {
    return this.data.ownerId;
  }

  public get avatarText() {
    return this.data.name[0].toUpperCase();
  }

  /**
   * @override
   */
  compareTo(other: Player) {
    let a = this.name.toLowerCase();
    let b = other.name.toLowerCase();

    if (a < b) return -1;
    if (a > b) return 1;

    // use default sorting as tie-breaker
    return super.compareTo(other);
  }
}

const PlayersCollectionContext = createContext<Collection<
  PlayerData,
  Player
> | null>(null);

const PlayersCollectionProvider: FunctionComponent = ({ children }) => {
  const players = useCollection('players', Player);

  return (
    <PlayersCollectionContext.Provider value={players}>
      {children}
    </PlayersCollectionContext.Provider>
  );
};

export const usePlayersCollection = () => {
  const players = useContext(PlayersCollectionContext);

  if (!players) {
    throw new Error(
      'Must use usePlayersCollection within PlayersCollectionProvider'
    );
  }

  return players;
};

export default PlayersCollectionProvider;
