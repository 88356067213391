import React, { PropsWithChildren, useState } from 'react';
import {
  DialogTitle,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  ListItemSecondaryAction,
  Dialog,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Close';
import useToggle from '../../hooks/useToggle';
import { useTranslation } from 'react-i18next';

export interface InputComponentProps<T> {
  id: string;
  value: T | null;
  onChange: (value: T | null) => void;
}

export interface SettingComponentProps<T> {
  optional?: boolean;
  id: string;
  icon?: JSX.Element;
  label: string;
  value: T | null;
  onChange: (value: T | null) => void;
}

interface SettingProps<T> extends SettingComponentProps<T> {
  valueFormatter?: (value: T) => string;
  InputComponent: React.FunctionComponent<InputComponentProps<T>>;
}

function Setting<T>({
  optional = false,
  id,
  icon,
  label,
  value,
  valueFormatter = (value: unknown) => value as string,
  onChange,
  InputComponent,
}: PropsWithChildren<SettingProps<T>>) {
  const { t } = useTranslation();

  const [open, toggleOpen] = useToggle();
  const [editValue, setEditValue] = useState(value);

  const titleId = `${id}-dialog-title`;

  const onCancel = () => {
    toggleOpen();
    setEditValue(value);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    toggleOpen();
    onChange(editValue);
  };

  const onClear = () => {
    setEditValue(null);
    onChange(null);
  };

  const secondaryText =
    value !== null ? valueFormatter(value) : t('settings.none');

  return (
    <>
      <ListItem button onClick={toggleOpen}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} secondary={secondaryText} />
        {optional && value !== null && (
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label={t('actions.clear')}
              onClick={onClear}
            >
              <ClearIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>

      <Dialog aria-labelledby={titleId} open={open} onClose={toggleOpen}>
        <DialogTitle id={titleId}>{label}</DialogTitle>

        <form onSubmit={onSubmit}>
          <DialogContent>
            <InputComponent
              id={id}
              aria-labelledby={titleId}
              value={editValue}
              onChange={(value) => setEditValue(value)}
            />
          </DialogContent>

          <DialogActions>
            <Button color="primary" onClick={onCancel}>
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Ok
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default Setting;
