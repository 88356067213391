import React, { FunctionComponent, SyntheticEvent, useState } from 'react';
import { Typography, Box, ButtonBase, Snackbar } from '@material-ui/core';
import BackspaceIcon from '@material-ui/icons/BackspaceOutlined';
import ForwardIcon from '@material-ui/icons/ArrowForward';
import ReplayIcon from '@material-ui/icons/Replay';
import styled from 'styled-components';
import BottomPaper from '../styles/BottomPaper';
import mexp from 'math-expression-evaluator';

const MaxLength = Number.MAX_SAFE_INTEGER.toString().length;

const NumberPadGrid = styled.div`
  flex-grow: 1;
  flex-shrink: 0;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;

  overflow: hidden;
`;

const ControlsGrid = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[800]};
  border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius}px;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;

  overflow: hidden;
`;

interface NumberPadProps {
  value: number;
  onChange: (value: number) => void;
  display?: string;
  onDisplayChange: (display?: string) => void;
  onNextClicked: () => void;
  isFinal: boolean;
}

const NumberPad: FunctionComponent<NumberPadProps> = ({
  value,
  onChange,
  display,
  onDisplayChange,
  onNextClicked,
  isFinal,
}) => {
  const [error, setError] = useState<string | undefined>();

  const setDisplay = (newDisplay?: string) => {
    if ((newDisplay?.length ?? 0) > MaxLength) {
      return;
    }

    let parsed = parseInt(newDisplay ?? '0');
    if (!isNaN(parsed)) {
      onChange(parsed);
    }
    onDisplayChange(newDisplay);
  };

  const appendDisplay = (value: string) => {
    setDisplay((display ?? '') + value);
  };

  const onNumberClicked = (number: number) => {
    appendDisplay(number.toString());
  };

  const operators = ['-', '+', '÷', '×'];

  const displayWithoutPrefix =
    display?.startsWith('-') || display?.startsWith('+')
      ? display.substring(1)
      : display;
  const requiresEval =
    displayWithoutPrefix &&
    operators.some((operator) => displayWithoutPrefix.includes(operator));

  const onSubmit = () => {
    if (!requiresEval) {
      onNextClicked();
    } else {
      try {
        const result = Math.round(
          +mexp.eval(display!.replace(/×/g, '*').replace(/÷/g, '/'))
        );
        if (result === Infinity) {
          throw new Error('Invalid result.');
        }
        setDisplay(result.toString());
      } catch (e) {
        setError(e.message.replace(/\*/g, '×').replace(/\//g, '÷'));
      }
    }
  };

  return (
    <>
      <BottomPaper>
        <Box display="flex">
          <NumberPadGrid>
            {new Array(9).fill('').map((_, index) => (
              <NumberPadButton
                key={index}
                onClick={() => onNumberClicked(index + 1)}
              >
                {index + 1}
              </NumberPadButton>
            ))}

            <NumberPadButton
              onClick={() => setDisplay(display?.slice(0, -1) ?? '0')}
              onContextMenu={(e) => {
                e.preventDefault();
                setDisplay();
              }}
            >
              <BackspaceIcon />
            </NumberPadButton>

            <NumberPadButton onClick={() => onNumberClicked(0)}>
              0
            </NumberPadButton>

            <NumberPadButton onClick={onSubmit}>
              {requiresEval ? '=' : isFinal ? <ReplayIcon /> : <ForwardIcon />}
            </NumberPadButton>
          </NumberPadGrid>
          <ControlsGrid>
            {operators.map((operator) => (
              <NumberPadButton
                key={operator}
                onClick={() => appendDisplay(operator)}
              >
                {operator}
              </NumberPadButton>
            ))}
          </ControlsGrid>
        </Box>
      </BottomPaper>

      <Snackbar
        open={!!error}
        onClose={() => setError(undefined)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message={error}
        autoHideDuration={4000}
      />
    </>
  );
};

const buttonSize = 10;
const NumberPadButtonBase = styled(ButtonBase)`
  width: ${({ theme }) => theme.spacing(buttonSize)}px;
  height: ${({ theme }) => theme.spacing(buttonSize)}px;

  .MuiTouchRipple-root {
    overflow: visible;
    transform: scale(1.25) translate(-12.5%, -12.5%);
  }
`;

interface NumberPadButtonProps {
  onClick?: () => void;
  onContextMenu?: (e: SyntheticEvent) => void;
}

const NumberPadButton: FunctionComponent<NumberPadButtonProps> = ({
  children,
  onClick,
  onContextMenu,
}) => {
  return (
    <Box display="flex" justifyContent="center">
      <NumberPadButtonBase
        centerRipple
        onClick={onClick}
        onContextMenu={onContextMenu}
      >
        {React.Children.map(children, (child) =>
          typeof child === 'object' ? (
            child
          ) : (
            <Typography variant="h6">{child}</Typography>
          )
        )}
      </NumberPadButtonBase>
    </Box>
  );
};

export default NumberPad;
