import React, { FunctionComponent } from 'react';
import { TextField } from '@material-ui/core';
import Setting, { InputComponentProps, SettingComponentProps } from './Setting';

const NumberInputComponent: FunctionComponent<InputComponentProps<number>> = ({
  id,
  value,
  onChange,
}) => {
  return (
    <TextField
      margin="dense"
      fullWidth
      autoFocus
      type="number"
      id={id}
      value={value ?? ''}
      onChange={(e) => onChange(+e.currentTarget.value)}
    />
  );
};

const NumberSetting: FunctionComponent<SettingComponentProps<number>> = (
  props
) => {
  return <Setting {...props} InputComponent={NumberInputComponent} />;
};

export default NumberSetting;
